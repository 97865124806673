import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  myurls = [
    { 
      url: 'https://wm-app.bizofit.com/KYB2/#/New_Application_Page?formId=770cd042-4cd1-473b-9e20-47fcbeadb915',
      label: 'SME Loan'
    },
    { 
      url: 'https://wm-app.bizofit.com/KYB2/#/New_Application_Page?formId=e2cae2cb-a2ea-440c-a39b-7edf5ab3a47a',
      label: 'Savings'
    },
    { 
      url: 'https://wm-app.bizofit.com/KYB2/#/New_Application_Page?formId=1aefccb2-239b-4d34-baff-14d66b4a4003',
      label: 'Personal Loans'
    },
    { 
      url: 'https://wm-app.bizofit.com/KYB2/#/New_Application_Page?formId=8ab5d9d7-79b4-4a90-8a03-60832ff29341',
      label: 'SBA Loan'
    },
    { 
      url: 'https://wm-app.bizofit.com/KYB2/#/New_Application_Page?formId=67fd10ef-15a1-4bce-abd4-8591d9bce4d2',
      label: 'Home Loan'
    },
    { 
      url: 'https://wm-app.bizofit.com/KYB2/#/New_Application_Page?formId=4c14cb27-c46a-48f2-941d-e2b6b685fc5e',
      label: 'Checking Account'
    },
    { 
      url: 'https://wm-app.bizofit.com/KYB2/#/New_Application_Page?formId=ca11668c-21cd-489a-b97e-84bd4572672a',
      label: 'Certificate of Deposit'
    },
    { 
      url: 'https://wm-app.bizofit.com/KYB2/#/New_Application_Page?formId=b5776959-842b-4639-b452-7ee684894348',
      label: 'Credit Card'
    }
  ]

  
  constructor() { 
    var token1:any=localStorage.getItem('token');
    console.log(token1);
    
    this.myurls.map(ele => ele.url = ele.url + '&t=' +  encodeURIComponent(token1));
   // this.myurls.map(ele => ele.url = ele.url + '&t=' +  localStorage.getItem('token'));
    console.log(this.myurls);  
  }

  ngOnInit(): void {
  }

}
