
<div class="container mt-4" *ngIf="userLogin">
    <div class="row">
      <div class="col-md-5 m-auto">
        <div class="card animated jello">
          <div class="card-header bg-primary text-white">
            <p class="h4">Login</p>
            <p>Use any one option to get started!</p>
          </div>
          <div class="card-body bg-light">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a class="nav-link active" data-toggle="tab" href="#home">Enter Your Mobile</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#menu1">Enter Your Email</a>
                    </li>
                  </ul>
                
                  <!-- Tab panes -->
                  <div class="tab-content">
                    <div id="home" class="container tab-pane active"><br>
                        <form>
                            <div class="form-group">
                              <input
                                name="username" [(ngModel)]="user.username" (change)="setUsername()"
                                type="text" class="form-control" placeholder="Enter your Phone">
                            </div>
                            <div>
                              <button type="button" (click)="submitLogin()" class="btn btn-primary btn-sm btn-block">Get Started</button>
                            </div>
                            <small>Don't have an Account ?
                              <a routerLink="/users/register" class="text-white">Register</a>
                            </small>
                      </form>
                       
                    </div>
                    <div id="menu1" class="container tab-pane fade"><br>
                        <form>
                            <div class="form-group">
                                <input
                                  name="email"
                                  type="email" class="form-control" [(ngModel)]="user.username" (change)="setUsername()" placeholder="Enter your Email">
                              </div>
                              <div>
                                <button type="button" (click)="submitLogin()" class="btn btn-primary btn-sm btn-block">Get Started</button>
                              </div>
                              <small>Don't have an Account ?
                                <a routerLink="/users/register" class="text-white">Register</a>
                              </small>
                        </form>
                    </div>
                  </div>


          </div>
          
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-3" *ngIf="userVerify">
    <div class="row">
      <div class="col-md-5 m-auto">
        <div class="card animated jello">
          <div class="card-header bg-primary text-white">
            <p class="h4">Welcome User!</p>
            <p class="h6">Verify OTP</p>
          </div>
          <div class="card-body bg-light">
              <p>We have send you OTP (One Time Password) on your mobile. Verify to continue your application process.</p>
            <form>
              <div class="form-group">
                <input
                  name="otp" [(ngModel)]="userVerifyOtp.otp"
                  type="number" class="form-control" placeholder="Enter OTP">
              </div>
              <div>
                <input type="button" (click)="submitVerifyOtp()" class="btn btn-primary btn-sm" value="Verify and Continue">
              </div>
              
            </form>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  
  