import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {IUser} from '../models/IUser';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient) { }

  // login
  public login(user:IUser):Observable<{result : string}>{
   let dataURL:string = `http://18.118.4.63:8093/prospect/v1/api/auth/generate/otp`;
   return this.httpClient.post<{result : string}>(dataURL,user).pipe(
     retry(1),
      // catchError(this.handleError)
   );
 }
   // verifyOtp
   public verifyOtp(user:IUser):Observable<{result : string , token : string , user : IUser}>{
     let dataURL:string = `http://18.118.4.63:8093/prospect/v1/api/auth/verify/otp`;
     return this.httpClient.post<{result : string , token : string, user : IUser}>(dataURL,user).pipe(
       retry(1),
       // catchError(this.handleError)
     );
   }
 
     // get Token
     public getToken():any{
       return localStorage.getItem('token');
     }
 
 
 
   //Errorhandle
 
   public handleError(error:HttpErrorResponse){
     let errorMessage:string = '';
     if(error.error instanceof ErrorEvent){
       // client Error
       errorMessage = `Error : ${error.error.message}`
     }
     else{
       // server error
       errorMessage = `Status : ${error.status} \n Message: ${error.message}`;
     }
     return throwError(() => new Error('errorMessage'));
   }
 }
 