import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public user:any = {
    username: ''
   };

   public username = '';

   
 
   public userVerifyOtp:any ={
     username: "",
     otp:""
   }

   setUsername() {
    this.username = this.user.username;
    this.userVerifyOtp.username = this.username;
    console.log(this.userVerifyOtp.username);     
  }
   
   public errorMessage!: string;
 
   constructor( private router:Router, private userService:UserService,) { }
 
   ngOnInit(): void {
   }
  
  
   //show hide div variables
 userLogin = true;
 userVerify = false;
 
  // submitLogin
 
  public submitLogin() {
   this.userLogin = false;
   this.userVerify = true;
   this.userService.login(this.user).subscribe(
    (data:any) => {
    //redirect to verify otp page
    console.log(data)
   }, (error:any) => {
     this.errorMessage=error;
   }
   )
 };
 
 public submitVerifyOtp() {
   this.userLogin = true;
   this.userVerify = false;
   console.log(this.userVerifyOtp);
   this.userService.verifyOtp(this.userVerifyOtp).subscribe((data:any) => {
        let token=data.accessToken;
       //store the token in local stroge
       localStorage.setItem('token', token);
       localStorage.setItem('userVerifyOtp', JSON.stringify(data.userVerifyOtp))
       //redirect to page of landing page
       this.router.navigate(['/landing-page'])
     }, (error:any) => {
       this.errorMessage=error;
     }
     )
 }
 

 }