<!-- header -->
<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
  <!-- Brand/logo -->
  <a class="navbar-brand" routerLink="/">Your Bank</a>
  
</nav>

<!-- Products -->

<div class="container mt-5">
  <ul class="nav nav-pills">
    <li class="nav-item" *ngFor="let url of myurls;">
      <a class="nav-link" [attr.href]="url.url" target="_blank">{{url.label}}</a>
    </li>
  </ul>
</div>



<!-- <div class="container mt-5">
  <ul class="nav nav-pills">
    <li class="nav-item" *ngFor="let url of urls;">
      <a class="nav-link" [attr.href]="url.url">{{url.label}}</a>
    </li>
  </ul>
</div> -->